import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/features/auth.service';

@Component({
  selector: 'route-fortify-v2-logout',
  templateUrl: './fortify-v2-logout.component.html',
  styleUrls: ['./fortify-v2-logout.component.scss']
})
export class FortifyV2LogoutComponent implements OnInit {

  constructor(
    private readonly auth: AuthService
  ) { }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // Set the initial padding style for the body element (measured in Edge).

    /**
     * Load the discovery document and logout.
     * ! At this point we cannot remove the session storage, this is handled by the angular-oauth2-oidc library.
     */
      this.auth.loadDiscoveryDocumentAndLogout(); // Load the discovery document and logout.
  }
}
