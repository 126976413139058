import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'route-fortify-v2-redirect',
  templateUrl: './fortify-v2-redirect.component.html',
  styleUrls: ['./fortify-v2-redirect.component.scss']
})
export class FortifyV2RedirectComponent implements OnInit {

  accessToken: string | null;

  constructor(
    private auth: AuthService,
    private router: Router
  ) { 
    this.authorize();
  }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
  }

  authorize() {
    //
    //  1. Check if the token exists - if not login
    //  2. check valid token - if not logout.
    //  3. if the token exist and valid, then redirect to callback.
    // 
    this.accessToken = this.auth.checkAccessToken();
    if (!this.accessToken) { // First time login.
      sessionStorage.clear(); // to clear out the previous connections stored.
      console.warn('---------LOGGING IN ----------' );
      this.auth.loginCode();
    } else if (this.auth.validateToken()) { // Valid token from Fortify
      console.warn('--------- Validate Token ----------' );
      this.router.navigate(['auth/callback']);
    } else {
      console.warn('---------LOGGING OUT ----------' ); 
      sessionStorage.clear(); // Clear out the previous connections stored.
      this.auth.loadDiscoveryDocumentAndLogout();
    }
  }

  

}
