//
// Angular
//
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//
// Material
//
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
//
// ngrx / rxjs
//
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { effects } from './store/effects/index';
import { reducers } from './store/reducers/index';
//
// Directives and Environments
//
import { AutoFocusDirective } from './auto-focus.directive';
import { environment } from 'src/environments/environment.prod';
//
// 3rd Party
//
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgSelectModule } from '@ng-select/ng-select';
//
// Services
//
import { FortifyOAuthModule } from './auth/fortify/fortify-oauth.module';
import { FortifyOAuthService } from './auth/fortify/fortify-oauth.service';
import { FortifyOAuthStorage, FortifyOAuthStorageFactory } from './auth/fortify/fortify-oauth-storage';
import { LcptrackerOAuthService } from './auth/lcptracker/lcptracker-oauth.service';
import { LcptrackerOAuthModule } from './auth/lcptracker/lcptracker-oauth.module';
import { LcpTrackerOAuthStorage, LcpTrackerOAuthStorageFactory } from './auth/lcptracker/lcptracker-oauth-storage';
import { LcpTrackerRedirectComponent} from './routes/login/lcptracker-redirect/lcptracker-redirect.component';
//
// Components ( Reusable )
//
import { AccountComponent } from './routes/login/account/account.component';
import { AlertComponent } from './components/shared/alert/alert.component';
import { AlertDialogComponent } from './components/shared/alert/dialog/alert-dialog.component';
import { AppComponent } from './app.component';
import { BusyComponent } from './components/shared/busy/busy.component';
import { BusyDialogComponent } from './components/shared/busy/dialog/busy-dialog.component';
import { CustomSnackBarComponent } from './components/shared/custom-snack-bar/custom-snack-bar.component';
import { ErrorWizComponent } from './components/shared/error-wiz/error-wiz.component';
import { FortifyAccountsComponent } from './routes/fortify-accounts/fortify-accounts.component';
import { FortifyDirectComponent } from './routes/fortify-direct/fortify-direct.component';
import { FortifyImpersonateComponent } from './routes/fortify-impersonate/fortify-impersonate.component';
import { FortifyLogoutComponent } from './routes/logout/fortify-logout/fortify-logout.component';
import { FortifyRedirectComponent } from './routes/login/fortify-redirect/fortify-redirect.component';
import { FortifyV2RedirectComponent } from './features/account-selector/fortify-v2-redirect/fortify-v2-redirect.component';
import { FortifyV2CallbackComponent } from './features/account-selector/fortify-v2-callback/fortify-v2-callback.component';
import { FortifyV2AccountsComponent } from './features/account-selector/fortify-v2-accounts/fortify-v2-accounts.component';
import { FortifyV2LogoutComponent } from './routes/logout/fortfy-v2-logout/fortify-v2-logout.component';
import { ImpersonateComponent } from './routes/login/impersonate/impersonate.component';
import { LoginComponent } from './routes/login/login.component';
import { PasswordComponent } from './routes/login/password/password.component';
import { UsernameComponent } from './routes/login/username/username.component';
import { ProgressComponent } from './components/shared/progress/progress/progress.component';
import { FooterComponent } from './components/footer/footer.component';
import { TopPageComponent } from './components/top-page/top-page.component';
import { FullContractorComponent } from './routes/login/full-contractor/full-contractor.component';
import { ResponseBannerComponent } from './routes/login/full-contractor/response-banner/response-banner.component';
import { PurchaseLicenseComponent } from './routes/login/account/purchase-license/purchase-license.component';
import { PurchaseCallbackComponent } from './routes/login/purchase-callback/purchase-callback.component';
import { TpaInfoComponent } from './routes/login/account/tpa-info/tpa-info.component';

import { SsoNet6Component } from './auth/net6/components/sso-net6/sso-net6.component';
import { SsoNet6AccountsComponent } from './auth/net6/components/sso-net6-accounts/sso-net6-accounts.component';
import { SsoNet6CallbackComponent } from './auth/net6/components/sso-net6-callback/sso-net6-callback.component';
import { FullContractorV2Component } from './auth/net6/components/full-contractor/full-contractor.component';
import { FullContractorFormComponent } from './auth/net6/components/full-contractor/full-contractor-form/full-contractor-form.component';
import { Net6SharedModule } from './auth/net6/shared/net6-shared.module';
import { ImpersonateV2Component } from './features/impersonate-v2/impersonate.component';
import { TpaInfoBannerComponent } from './features/account-selector/fortify-v2-accounts/tpa-info-banner/tpa-info-banner.component';
import { OtpEmailComponent } from './auth/net6/components/full-contractor/otp-email/otp-email.component';



@NgModule({
  declarations: [
    AppComponent,
    AccountComponent,
    AlertComponent,
    AlertDialogComponent,
    AutoFocusDirective,
    BusyComponent,
    BusyDialogComponent,
    CustomSnackBarComponent,
    ErrorWizComponent,
    FooterComponent,
    FortifyAccountsComponent,
    FortifyDirectComponent,
    FortifyImpersonateComponent,
    FortifyRedirectComponent,
    FortifyV2RedirectComponent,
    FortifyV2CallbackComponent,
    FortifyV2AccountsComponent,
    FortifyV2LogoutComponent,
    ImpersonateComponent,
    ImpersonateV2Component,
    LcpTrackerRedirectComponent,
    LoginComponent,
    PasswordComponent,
    ProgressComponent,
    TopPageComponent,
    UsernameComponent,
    FortifyLogoutComponent,
    PurchaseLicenseComponent,
    FullContractorComponent,
    FullContractorV2Component,
    ResponseBannerComponent,
    PurchaseCallbackComponent,
    TpaInfoComponent,
    SsoNet6Component,
    SsoNet6CallbackComponent,
    SsoNet6AccountsComponent,
    FullContractorFormComponent,
    TpaInfoBannerComponent,
    OtpEmailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    Net6SharedModule,
    //
    // Material Design
    //
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    //
    // 3rd Party
    //
    NgSelectModule,
    OAuthModule.forRoot(),
    LcptrackerOAuthModule.forRoot(),
    FortifyOAuthModule.forRoot(),
    //
    // NgRx
    //
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AlertDialogComponent,
    BusyDialogComponent,
    CustomSnackBarComponent,
    FullContractorV2Component
  ],
  providers: [
    { provide: LcpTrackerOAuthStorage, useFactory: LcpTrackerOAuthStorageFactory },
    { provide: FortifyOAuthStorage, useFactory: FortifyOAuthStorageFactory },
    LcptrackerOAuthService,
    FortifyOAuthService,
  ]
})
export class AppModule { }
