<ng-template #apiCall>
    <div class="goTo">
        <h4>{{type}}</h4>
    </div>
</ng-template>


<ng-container *ngIf="api == false">
<footer class="footer-login">
    <div class="copyright-background"></div>
    <div class="copyright-links">
        <ul>
            <li><a tabindex="0" target="_blank" href="https://www.lcptracker.com/contact/contact-sales">Contact Sales</a></li>
            <li><a tabindex="0" target="_blank" href="https://www.lcptracker.com/contact/contact-support">Contact Support</a></li>
            <li><a tabindex="0" target="_blank" href="https://www.lcptracker.com/about/company">About</a></li>
            <li><a tabindex="0" target="_blank" href="https://lcptracker.com/education-webinar">Webinar Sign Up</a></li>
            <li><a tabindex="0" target="_blank" href="https://www.lcptracker.com/contact/demo-sign-up">Demo Sign Up</a></li>
            <li><a tabindex="0" target="_blank" href="https://lcptracker.com/privacy-policy-for-lcptracker">Privacy Policy</a></li>
            <li><a tabindex="0" target="_blank" href="https://lcptracker.com/ccpa/">CA Privacy Rights</a></li>
        </ul>
        <p>Copyright LCPtracker, Inc. All Rights Reserved</p>
    </div>
</footer>
</ng-container>
<ng-container *ngIf="api == true">
<footer class="footer-login">
    <div class="copyright-background"></div>
    <div class="copyright-links">
        <ul>
            <li>
                <a tabindex="0" 
                    (click)="getUserName()"
                    (keyup.enter)="getUserName()"
                    >Contact Sales
                </a>
            </li>
            <li>
                <a tabindex="0" 
                    (click)="getAccounts()"
                    (keyup.enter)="getAccounts()"
                    >Contact Support
                </a>
            </li>
            <li><a tabindex="0" target="_blank" href="https://www.lcptracker.com/about/company">About</a></li>
            <li><a tabindex="0" target="_blank" href="https://lcptracker.com/education-webinar">Webinar Sign Up</a></li>
            <li><a tabindex="0"
                    (click)="getClaims()"
                    (keyup.enter)="getClaims()"
                    >Demo Sign Up</a></li>
        </ul>
        <p>Copyright LCPtracker, Inc. All Rights Reserved</p>
    </div>
</footer>
</ng-container>




