import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode'; // https://www.npmjs.com/package/jwt-decode
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { trackerConfigCode, trackerLogoutCode, userPortalConfigCode } from './v2.config';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/internal/operators/map';
import { SignatureValidationHandler } from '../services/oidc/signature-validation-handler';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token! : string;
  token$ = new BehaviorSubject<any>(null);
  decodedToken!: any;

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService
  ) { }


     /**
     * Extended claims from .NET6 (https://{{host}}/api/RbsClaims/V02/json/GetExtendedIdentityClaims)
     * @returns
     */
    rbsClaims(): Observable<any> {
      const token = this.oAuthService.getAccessToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const url = environment.fortifyV2APIUrl + '/RbsClaims/V02/json/GetExtendedIdentityClaims'
      return this.http.get<any>(url,  {headers}).pipe(map(res => res.data))
    }

  setToken(token: string) {
    if (token) {
      this.token = token;
    }
  }

  getToken$() {
    return this.token$.asObservable();
  }
  setToken$(value) {
    this.token$.next(value)
  }

  decodeToken() {
    if (this.token) {
    this.decodedToken = jwt_decode(this.token);
    }
  }

  getDecodeToken() {
    return jwt_decode(this.token);
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['name'] : null;
  }

  getEmail() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['email'] : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['exp'] : null;
  }

  getIdentityClaims() {
    return this.oAuthService.getIdentityClaims();
  }

  getAccessToken() {
    if (!this.oAuthService.hasValidAccessToken()) {
      console.log( '-- does not have a valid access Token -- ');
      return null;
    }
    const accessToken = this.oAuthService.getAccessToken();
    return accessToken;
  }
  checkAccessToken() {
    return this.oAuthService.getAccessToken();
  }

  async validateAtHash(input) {
     return await this.oAuthService.tokenValidationHandler.validateAtHash(input)
            .then ( res => {
              console.log( 'oAuthService.tokenValidationHandler.validateAtHash : ' + res)
              return res; 
            });
  }

  validateToken() {
    return this.oAuthService.hasValidAccessToken();
  }

  checkSessionTerminated() {
    return this.oAuthService.events.pipe(filter(e => e.type === 'session_terminated'))
  }

  /**
   * Refresh from the oidc authentication.
  */
  refresh(): void {
    this.oAuthService.refreshToken();
  }

  silentRefresh() {
    this.oAuthService.setupAutomaticSilentRefresh();
  }

  /**
   * Logout from the oidc authentication.
  */
  logout(noRedirectToLogoutUrl?: boolean): void {
    if (noRedirectToLogoutUrl) {
      this.oAuthService.logOut(true);
    }
    else {
      this.oAuthService.logOut();
    }
    
  }

  /**
   * Tracker Login through CODE Flow.
  */
  loginCode() {
    this.oAuthService.configure(trackerConfigCode);
    this.oAuthService.loadDiscoveryDocument()
    .then(res => {
      console.log('oAuthService.loginCode(trackerConfigCode)' + res);
      this.oAuthService.initCodeFlow();
    });
  }

  /**
   * Tracker discovery document Code Flow
  */
   discoverCode() {
    this.oAuthService.configure(trackerConfigCode);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.loadDiscoveryDocumentAndTryLogin()
    .then(res => {
      let data = this.getIdentityClaims();
        console.log ('getIdentityClaims : ' + JSON.stringify(data));
        let token =  this.oAuthService.getAccessToken();
        this.setToken(token);
        this.setToken$(token);
    });
  }

  /**
 * load discovery document and logout
  */
    loadDiscoveryDocumentAndLogout() {
      this.oAuthService.configure(trackerLogoutCode);
      this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
      this.oAuthService.loadDiscoveryDocumentAndTryLogin()
        .then(res => {
          console.log(res);
          let data = this.getIdentityClaims();
          console.log('getIdentityClaims : ' + JSON.stringify(data));
          // Check if the access token is valid
          if (this.oAuthService.hasValidAccessToken()) {
            console.log('Token is valid');
            // Perform logout
            this.oAuthService.logOut();
          } else {
            console.log('Token is not valid');
            // Handle invalid token case
            console.log(trackerLogoutCode.postLogoutRedirectUri);
            window.location.replace(trackerLogoutCode.postLogoutRedirectUri);
          }
        })
        .catch(err => {
          console.error('Error loading discovery document', err);
        });
    }

  //Fortify V2 redirect from the Tracker cdn
  loginCodeTwoUserPortal(username) {

     this.oAuthService.configure(userPortalConfigCode);
     this.oAuthService.customQueryParams = { domain_hint: 'lcptracker.com', login_hint: username };

      this.oAuthService.tokenValidationHandler = new SignatureValidationHandler();
     this.oAuthService.loadDiscoveryDocument()
    .then(res => {
        console.log('oAuthService.loginCode(trackerConfigCode)' + res);
          this.oAuthService.initCodeFlow();
      });
   }



}
