import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LcpService } from 'src/app/services/lcp/lcp.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { Account } from 'src/app/models/account.model';
import { FortifyV2Account } from './interfaces/fortify-v2-account.interface';
import { TrackerAccountWithExtendedClaims } from './interfaces/tracker-account-with-extended-claims.interface';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'route-fortify-v2-accounts',
  templateUrl: './fortify-v2-accounts.component.html',
  styleUrls: ['./fortify-v2-accounts.component.scss']
})
export class FortifyV2AccountsComponent implements OnInit {
  loading!: boolean;
  orgList: Array<any> = [];
  orgListFiltered: Array<any> = [];
  displayTpaInfoBanner: boolean[] = [];
  orgCodesFromFortify: any;
  loggedInUser: string;
  email: string;
  name: string;
  preferred_username: string;
  accounts_username: string;
  accounts_supportUsername: string;
  @ViewChild('value', { static: false }) value: ElementRef;
  searchCriteria = new FormControl('', Validators.minLength(2));

  constructor(
    private readonly auth: AuthService,
    private readonly lcpService: LcpService,
    private readonly route: ActivatedRoute
  ) {
    this.setInitialStateAccounts();
  }

  @HostListener('window:popstate', ['$event'])
  onPopstate(event: Event) {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (isFirefox) {
      event.preventDefault();
      location.href = environment.userPortal;
    }
  }


  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
    this.searchAccounts();
  }

  /**
   * Extracts the User Data from the request to Tracker
   *
   * @param res
   */
  setDisplays(res) {
    this.accounts_username = res[1].Username;
    this.accounts_supportUsername = res[1].SupportUsername;
    this.loading = false;
  }

  setOrgCode(org: any): void {
    if (this.isTPARuleSatisfied(org)) {

      this.lcpService.setOrgCode(org.OrgCode, this.orgList, org.RoleId)
        .subscribe(res => {
          console.log('setOrgCode() : ' + JSON.stringify(res));
          // Admin
          if (res.AccountType == 'Admin') {
            console.log('------ Logging as Administrator --------');
            window.location.href = `${environment.webAppUrl}/Projects/UserAgreement`;
          }
          // Contractor
          if (res.AccountType == 'Contractor') {
            console.log('------ Logging as Contractor --------');
            window.location.href = `${environment.webAppUrl}/Projects/UserAgreement`;
          }
        });
      return;
    }

    const organizationIndex = this.orgList.indexOf(org);

    if (this.displayTpaInfoBanner[organizationIndex] === false) {
      this.displayTpaInfoBanner[organizationIndex] = true;
      return;
    }

    if (!this.isTPARuleSatisfied(org) && this.displayTpaInfoBanner[organizationIndex] === true) {
      this.displayTpaInfoBanner[organizationIndex] = false;
    }

  }

  /**
   * Compares the list of organizations of the user in Tracker and
   * Fortify and the ones that exist in both are stored in the list
   * of organizations to display
   *
   * @param organizationsResponses
   */
  compareOrganizations(organizationsResponses: any): void {
    // TODO: Make array instead of matrix
    const fortifyV2Accounts = organizationsResponses[0][0].value;
    // console log the accounts.
    console.log('fortifyV2Accounts');
    console.table(fortifyV2Accounts);
    const trackerAccounts = organizationsResponses[1].Accounts;
    console.log('trackerAccounts');
    console.table(trackerAccounts);

    // filterOrganizations: It will contain the the list of Organizations that exist in
    // Tracker and Fortify V2 at the same time

    let filteredOrganizations: TrackerAccountWithExtendedClaims[] = []

    // Iterates over the Tracker Accounts
    trackerAccounts.forEach((trackerAccount: Account): void => {

      // Every account in Tracker is being searched in the list of
      // Organizations from Fortify V2 by comparing the Organization Code
      const fortifyOrganizationExistingInTracker: FortifyV2Account
        = fortifyV2Accounts.find((fortifyV2Account: FortifyV2Account) => {

          // Checks if the Organization Code matches
          if (trackerAccount.OrgCode.toLowerCase() === fortifyV2Account.code.toLowerCase()) {
            // If it does, it returns the value to fortifyOrganizationExistingInTracker;
            return fortifyV2Account;
          }

          return;

        });

      // Validates if the fortifyOrganizationExistingInTracker has value
      // which means a match was found between Tracker and Fortify V2
      if (fortifyOrganizationExistingInTracker) {

        // If there is a match it adds the object to the list of organization
        // with the claims combined
        filteredOrganizations.push({
          ...fortifyOrganizationExistingInTracker,
          ...trackerAccount
        });

        return;
      }

      // Else it adds the object but only with Tracker claims
      filteredOrganizations.push({
        ...trackerAccount,
        tpaRuleSatisfied: true
      });
    });

    console.log("Displayed org list: ",filteredOrganizations);
    // Assigns the filtered list to list that will be display on the screen
    if(filteredOrganizations.length){
      // Sort aphabetically by name
      this.orgList = filteredOrganizations.sort((a,b) => a.Name.localeCompare(b.Name));
      this.orgListFiltered = filteredOrganizations.sort((a,b) => a.Name.localeCompare(b.Name));
    }else{
      this.orgList = filteredOrganizations;
      this.orgListFiltered = filteredOrganizations;
    }

    // this.orgList = filteredOrganizations;
    // this.orgListFiltered = filteredOrganizations;
    console.log("Displayed sorted org list: ",this.orgListFiltered);
  }

  /**
   * Populates an empty array of booleans that handles what banners
   * will display, starting values are false or undefined
   *
   * FALSE means that the TPA rule wasn't satisfied, but initializes with
   * false since banners won't be displayed when page is initialized, only
   * when user clicks the account
   *
   * UNDEFINED means the TPA rule was satisfied, so it should never display
   * the banner even if the user clicks the account
   */
  initBannerDisplayFlags(): void {
    this.orgList.forEach((organization) => {
      if (!this.isTPARuleSatisfied(organization)) {
        this.displayTpaInfoBanner.push(false);
      }

      return undefined;
    });
  }

  /**
   * Checks if the TPA Rule of an organization is Satisfied
   *
   * @param organization
   * @returns
   */
  isTPARuleSatisfied(organization): boolean {
    // console.log(organization);
    if (organization.tpaRuleSatisfied) {
      return true;
    }

    return false;
  }

  searchAccounts(): void {
    this.searchCriteria.valueChanges
      .pipe(
        debounceTime(650),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        if(value.trim()){
          this.orgListFiltered = this.orgList.filter(item => item.Name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        }else{
          this.orgListFiltered = this.orgList;
        }
      });
  }

  setInitialStateAccounts(): void {
    this.route.data.subscribe((response) => {
      console.log('Activated Route data : ' + JSON.stringify(response.data));
      this.compareOrganizations(response.data);
      this.initBannerDisplayFlags();
      this.setDisplays(response.data);
    });
  }

  clearSearchInputAndResetList(): void {
   this.value.nativeElement.value = '';
   this.setInitialStateAccounts();
  }

  logout(): void {
    /**
     * Validate token or recover if needed
     * * At this point we do not need to remove the session storage, this is handled by the angular-oauth2-oidc library.
     * * Also, if the user can load the accounts, then the token is valid, and if this function is called, the user is logging out.
     * 
     */
      this.auth.loadDiscoveryDocumentAndLogout();
  }
}
